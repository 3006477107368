/* Portfolio Container */
.portfolio {
    width: 100%;
    padding: 6rem 5rem;
    height: 90vh;
    background-image: url(./../img/portfolio.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #ecf0f1;
  }
  
  .portfolio::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(72, 126, 176, 0.9); /* Use rgba for transparency */
    z-index: -1;
  }
  
  /* Carousel Items */
  .carousel {
    fade: true;
    controls: false;
    /* Add any additional carousel styles here */
  }
  
  .carousel-item {
    /* Add any specific carousel item styles here */
  }
  
  .carousel_img {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #dcdde1;
  }
  
  /* Portfolio List Styles */
  .portfolio_list h3,
  .portfolio_list p {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  /* Media Queries */
  @media screen and (max-width: 810px) {
    .portfolio {
      padding: 6rem 2rem !important;
    }
  
    .carousel_img {
      width: 320px;
      height: 320px;
    }
  }
  
  @media screen and (max-width: 496px) {
    .portfolio {
      padding: 4rem 2rem !important;
    }
  }
  