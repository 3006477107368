.banner{
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(./../img/banner.jpg);
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0rem 5rem;
    color: #ecf0f1;
    background-attachment: fixed;
}

.banner .banner_list{
    padding: 3rem 0rem;
    margin-top: 70px;
}

.banner .banner_list h5{
    font-size: 2rem;
}

.banner .banner_list h3{
    font-size: 2.6rem;
    font-weight: bold;
}

.banner .banner_list p{
    font-size: 1.1rem;
    color: #bdc3c7;
    margin: 0.4rem 0rem 1rem 0rem;
}

.banner .banner_list .banner_btn button{
    background-color: white;
    outline: none;
    border: none;
    padding: 8px 20px;
    margin: 0px 5px;
    font-size: 1rem;
    color: #00ADEF;
    cursor: pointer;
    box-shadow: 2px 2px 5px -1px rgba(25,21,21,0.75);
    transition: 0.3s ease;
}

.banner .banner_list .banner_btn .first_btn{
    background-color: #00ADEF;
    color: white;
    margin-left: 0px;
}

.banner .banner_list .banner_btn .first_btn:hover{
    color: #00ADEF;
    background-color: white;
}

.banner .banner_list .banner_btn button:hover{
    background-color: #00ADEF;
    color: white;
}

.banner .banner_list .banner_btn button .fa{
    margin-left: 2px;
    font-size: 1.3rem;
    position: relative;
    top: 3px;
}

@media screen and (max-width:810px){
    .banner{
        padding: 0rem 3rem;
    }
}


@media screen and (max-width:768px){
     .banner{
        padding: 0rem 2rem!important;
    }


    .banner .banner_list h5{
        font-size: 1.8rem!important;
    }
    
    .banner .banner_list h3{
        font-size: 2rem!important;
    }
    
    .banner .banner_list p{
        font-size: 1rem!important;
    }

}


@media screen and (max-width:400px){
    .banner{
        padding: 0rem 1rem!important;
    }

    .banner .banner_list h5{
        font-size: 1.2rem!important;
    }
    
    .banner .banner_list h3{
        font-size: 1.3rem!important;
        font-weight: bold!important;
    }
    
    .banner .banner_list p{
        font-size: 0.9rem!important;
     
    }

    .banner{
        height: 80vh!important;
    }


    .banner .banner_list .banner_btn button{
        padding: 8px 10px!important;
        font-size: 0.8rem!important;
    }
    

}