.contact{
    width: 100%;
    padding: 6rem 5rem;
    background-color: #ecf0f1;
}

.contact .contact_list{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.contact .form , .contact .contact_map{
    width: 48%;
}

.contact .form form input, .contact .form form textarea{
    width: 100%;
    border: 1px solid #2c3e50;
    outline: none;
    padding: 15px 5px;
    margin-bottom: 15px;
    font-size: 1rem;
    resize: none;
}

.contact .form form  button{
    padding: 10px 30px;
    background-color: #00ADEF;
    color: white;
    border-radius: 50px;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s ease;
}

.contact .form form  button:hover{
    background-color: #00ADEF;
}

.contact h3{
    text-align: center;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 15px;
}


@media screen and (max-width:812px){
    .contact{
        padding: 6rem 2rem!important;
    }
}



@media screen and (max-width:640px){
    .contact .contact_list{
        flex-direction: column!important;
    }

    .contact{
        padding: 4rem 1rem!important;
    }

    .contact .form , .contact .contact_map{
        width: 100%!important;
    }

    .contact .form{
        margin-bottom: 20px!important;
    }
   
}